import { QuickPopup } from "./QuickPopup";

export function presetPopup({ Popup, id, onClose, props }) {
  const methods = {};
  const assignOpenFn = fn => {methods['fnOpen'] = fn;}
  const assignCloseFn = fn => {methods['fnClose'] = fn;}
  const assignToggleFn = fn => {methods['fnToggle'] = fn;}
  const render = <QuickPopup Popup={Popup} popupId={id??''} openHandler={assignOpenFn} closeHandler={assignCloseFn} toggleHandler={assignToggleFn} onClose={onClose} {...props} />;
  return {
    render,
    openPopup: (componentProps) => methods['fnOpen']({ popupId: id??'', ...componentProps }),
    closePopup: () => methods['fnClose'](),
    togglePopup: (componentProps) => methods['fnToggle']({ popupId: id??'', ...componentProps })
  };
}