import LinkCell from "../../grid/cellstyles/linkCell";
import { presetPopup } from "../../grid/dialog/presetPopup/presetPopup";
import { ConsolidateModal } from "../../grid/dialog/Consolidate/ConsolidateModal";

export const getInboundColumn = () => {
const {render: ConsolidateModalRender, openPopup: openPopupConsolidate} = presetPopup({ Popup: ConsolidateModal });

  const openConsolidateModal = (e) => {
    openPopupConsolidate({internalId: e.row.data.InternalId, name: e.row.data.Name});
  }
  
  const columns = {};
  const localColumnKey = "col_inbound";
  columns[localColumnKey] = {
    caption: "Inbound",
    localColumnKey: localColumnKey,
    dataField: "Inbound",
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => LinkCell(e, openConsolidateModal),
    allowEditing: false,
    dataType: "number",
    width: 100
  };

  return {render: ConsolidateModalRender, colProps: columns};
}