import { useRef } from 'react'

export const initColumnSum = (initValue = null) => {
    return {
        "CommitTotal": initValue,
        "BO_total": initValue, 
        "poQtyReviewer": initValue, 
        "poQtyMarket": initValue, 
        "poQtyApprover": initValue, 
        "CostReviewer": initValue, 
        "CostMarket": initValue, 
        "CostApprover": initValue, 
        "CostSuggested": initValue, 
        "Suggested PO Qty": initValue
    };
}

export const useRefSumByColumn = () => {

    const refSumByCol = useRef({});
    const refSumByColDisplay = useRef({});

    const setSumByColumnDisplay = (key, value) => {
        const popupSum = refSumByColDisplay.current[key];
        if (popupSum) {
            popupSum.value = value;
            popupSum.title = value;
        }
    }

    const setSumByColumn = (key, value) => {
        const hdnSum = refSumByCol.current[key];
        if (hdnSum) {
            hdnSum.value = value;
            hdnSum.title = value;
        }
    }
    const getSumByColumn = (key) => {
        const hdnSum = refSumByCol.current[key];
        if (hdnSum) {
            return hdnSum.value;
        }
        else
            return 0;
    }

    return {refSumByCol, refSumByColDisplay, setSumByColumn, getSumByColumn, setSumByColumnDisplay};
}

export const updateColumnSum = (totalSumByColumn, setSumByColumn, getSumByColumn, setSumByColumnDisplay) => {

    Object.keys(totalSumByColumn).forEach( key => {
        if (totalSumByColumn?.[key] !== null) {
            const sumT = Intl.NumberFormat().format(Math.round(totalSumByColumn?.[key] * 100) / 100);
            setSumByColumn(key, sumT?? 0);
        }
        const sumTotal = getSumByColumn(key);
        setSumByColumnDisplay(key, sumTotal);
    });
}

export const SumByColumn = ({refSumByCol}) => {

    return (
        <>
            <input type="hidden" id="hdnSum_CommitTotal" ref={obj => refSumByCol.current["CommitTotal"] = obj} />
            <input type="hidden" id="hdnSum_BO_total" ref={obj => refSumByCol.current["BO_total"] = obj} />
            <input type="hidden" id="hdnSum_poQtyReviewer" ref={obj => refSumByCol.current["poQtyReviewer"] = obj} />
            <input type="hidden" id="hdnSum_poQtyMarket" ref={obj => refSumByCol.current["poQtyMarket"] = obj} />
            <input type="hidden" id="hdnSum_poQtyApprover" ref={obj => refSumByCol.current["poQtyApprover"] = obj} />
            <input type="hidden" id="hdnSum_CostReviewer" ref={obj => refSumByCol.current["CostReviewer"] = obj} />
            <input type="hidden" id="hdnSum_CostMarket" ref={obj => refSumByCol.current["CostMarket"] = obj} />
            <input type="hidden" id="hdnSum_CostApprover" ref={obj => refSumByCol.current["CostApprover"] = obj} />
            <input type="hidden" id="hdnSum_CostSuggested" ref={obj => refSumByCol.current["CostSuggested"] = obj} />
            <input type="hidden" id="hdnSum_SuggestedPOQty" ref={obj => refSumByCol.current["Suggested PO Qty"] = obj} />
        </>
    );
}
