import { YearLabel } from "./../functions/utils";
import LinkCell from "../../grid/cellstyles/linkCell";
import LinkTooltipCell from "../../grid/cellstyles/linkTooltipCell";
import LinkCellForDiffValue from "../../grid/cellstyles/linkCellForDiffValue";
import { presetPopup } from "../../grid/dialog/presetPopup/presetPopup";
import UniqueCustomerModal from "../../grid/dialog/UniqueCustomers/UniqueCustomerModal";

export const getUniqueCustomColumns = () => {
  const {render: UniqueCustomerModalRender, openPopup: openPopupUniqueCustomer} = presetPopup({ Popup: UniqueCustomerModal });

  const openUniqueCustomerModal = (e, {period, tab}) => {
    openPopupUniqueCustomer({internalId: e.row.data.InternalId, name: e.row.data.Name, Period: period, tab});
  }

  const calculateTopBuyerShare = (rowData) => {
    var column = rowData["TopBuyerShare"];
    if (column == null) return null;
    if (column === 'Infinity') return " ∞ ";
    return typeof column === 'number' ? column.toFixed(0) : "";
  }
  const columns = {};
  let localColumnKey = "col_y3";
  columns[localColumnKey] = {
    caption: YearLabel(3),
    localColumnKey: localColumnKey,
    dataField: "Y3",
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => LinkCell(e, openUniqueCustomerModal, {period: 6, tab: 1}),
    allowEditing: false,
    dataType: "number",
    width: 100
  };

  localColumnKey = "col_y2";
  columns[localColumnKey] = {
    caption: YearLabel(2),
    localColumnKey: localColumnKey,
    dataField: "Y2",
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => LinkCell(e, openUniqueCustomerModal, {period: 5, tab: 1}),
    allowEditing: false,
    dataType: "number",
    width: 100
  };

  localColumnKey = "col_y1";
  columns[localColumnKey] = {
    caption: YearLabel(1),
    localColumnKey: localColumnKey,
    dataField: "Y1",
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => LinkCell(e, openUniqueCustomerModal, {period: 4, tab: 1}),
    allowEditing: false,
    dataType: "number",
    width: 100
  };

  localColumnKey = "col_y0";
  columns[localColumnKey] = {
    caption: YearLabel(0),
    localColumnKey: localColumnKey,
    dataField: "Y0",
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => LinkCell(e, openUniqueCustomerModal, {period: 3, tab: 1}),
    allowEditing: false,
    dataType: "number",
    width: 100
  };

  localColumnKey = "col_l12M";
  columns[localColumnKey] = {
    caption: "L12M",
    localColumnKey: localColumnKey,
    dataField: "L12M",
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => LinkCell(e, openUniqueCustomerModal, {period: 2, tab: 1}),
    allowEditing: false,
    dataType: "number",
    width: 100
  };

  localColumnKey = "col_l3M";
  columns[localColumnKey] = {
    caption: "L3M",
    localColumnKey: localColumnKey,
    dataField: "L3M",
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => LinkCell(e, openUniqueCustomerModal, {period: 1, tab: 1}),
    allowEditing: false,
    dataType: "number",
    width: 100
  };

  localColumnKey = "col_uniqueCustomerCount";
  columns[localColumnKey] = {
    caption: "Unique Customers (L12M)",
    localColumnKey: localColumnKey,
    dataField: "Unique Customer Count",
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => LinkCell(e, openUniqueCustomerModal, {period: 7, tab: 0}),
    allowEditing: false,
    dataType: "number",
    width: 100
  };

  localColumnKey = "col_topBuyerShare";
  columns[localColumnKey] = {
    caption: "Top Buyer Share",
    localColumnKey: localColumnKey,
    dataField: "TopBuyerShare",
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => (!e.value)? '' : LinkCell(e, openUniqueCustomerModal, {period: 7, tab: 0}, { value: `${e.value}%` }),
    calculateCellValue: calculateTopBuyerShare,
    allowEditing: false,
    dataType: "number",
    width: 100
  };

  localColumnKey = "col_topBuyerName";
  columns[localColumnKey] = {
    caption: "Top Buyer Name",
    localColumnKey: localColumnKey,
    dataField: "TopBuyerName",
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => (!e.value)? '' : LinkTooltipCell(e, openUniqueCustomerModal, {period: 7, tab: 0}),
    allowEditing: false,
    dataType: "string",
    width: 100
  };
  
  localColumnKey = "col_exclusions";
  columns[localColumnKey] = {
    caption: "Exclusions",
    localColumnKey: localColumnKey,
    dataField: "Exclusions",
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => LinkCellForDiffValue(e, "No", openUniqueCustomerModal, {period: 7, tab: 2}),
    allowEditing: false,
    dataType: "string",
    width: 100
  };

  return {render: UniqueCustomerModalRender, colProps: columns};
}