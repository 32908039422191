import { CopyAll, Delete, Save, Share } from "@mui/icons-material";
import { Switch, Tooltip } from "devextreme-react";

const styles = {
    buttonContainer: {
      display: "inline-flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      gap: "10px",
      paddingRight: "10px"
    },
    iconButton: {
      cursor: "pointer"
    }
  };
  
  const CustomTooltip = ({ target, children }) => (
    <Tooltip
      target={target}
      showEvent="mouseenter"
      hideEvent="mouseleave"
      hideOnOutsideClick={false}
    >
      <div>{children}</div>
    </Tooltip>
  );
  
  export const renderButtons = (row, switchTemplate, showShareGridTemplateDialog, updateGridTemplate,showCloneGridTemplateDialog, deleteTemplate) => {
    return (
      <div style={styles.buttonContainer}>
        <Switch
          value={row.data.selected}
          height="18px"
          isValid={true}
          width="50px"
          onValueChange={() => switchTemplate(row.data.id)}
          name="radio-button-demo"
          id="enabledisable"
        >
        </Switch>
        {row.data.shared === false && (
          <>
            <div className="grid-icon-buttons" style={styles.iconButton} onClick={() => showShareGridTemplateDialog(row.data.templateId)} id={"share_" + row.data.templateId}>
              <CustomTooltip target={"#share_" + row.data.templateId}>
                Share the template with another user.
              </CustomTooltip>
              <Share />
            </div>
            <div className="grid-icon-buttons" style={styles.iconButton} onClick={() => updateGridTemplate(row.data.id)} id={"save_" + row.data.templateId}>
              <CustomTooltip target={"#save_" + row.data.templateId}>
                Save the current grid layout to this template.
              </CustomTooltip>
              <Save />
            </div>
            <div className="grid-icon-buttons" style={styles.iconButton} onClick={() => showCloneGridTemplateDialog(row.data.templateId)} id={"copy_" + row.data.templateId}>
              <CustomTooltip target={"#copy_" + row.data.templateId}>
                Copy the current template to another program/or category.
              </CustomTooltip>
              <CopyAll />
            </div>
            <div className="grid-icon-buttons" style={styles.iconButton} onClick={async () => await deleteTemplate(row.data.id)} id={"delete_" + row.data.templateId}>
              <CustomTooltip target={"#delete_" + row.data.templateId}>
                Delete this current template.
              </CustomTooltip>
              <Delete />
            </div>
          </>
        )}
      </div>
    );
  };