import {
    DataGrid,
    Summary,
    TotalItem,
    Column,
    Scrolling,
    FilterRow, HeaderFilter,
    FilterPanel} from "devextreme-react/data-grid";
import { PieChart } from 'react-minimal-pie-chart';


const chartCell = (cellData) => {
let value = 0;    
if (cellData.column.dataField === "AverageQty"){
    value = cellData.data.AverageQty
}
if (cellData.column.dataField === "AverageValue"){
    value = cellData.data.AverageValue
}
    const actual =value;
    const remaining = 100 - value;
    const roundedValue = (Math.round(actual * 100) / 100).toFixed(0);
    const data = [
        { title: 'actual', value: actual, color: '#CECECE' },
        { title: 'remaining', value: remaining, color: '#DAEAF1' }
    ];
    return (
        <div style={{display:"inline-flex"}}>
            <div style={{
                margin:"5px",
                
            }}>
            {roundedValue}%  
            </div>
         <PieChart style={{ height: "20px" }} data={data} />

        </div>
    );
}


const UniqueCustomersGrid = ({dataSource}) => {
    return (
        <>
                <DataGrid
                    dataSource={dataSource}
                    showBorders={true}
                    columnMinWidth={50}
                    columnAutoWidth={false}
                    columnResizingMode={'widget'}
                    width={"100%"}
                    height={"40vh"}
                >
                    <FilterRow visible={true} />
                    <FilterPanel visible={true} />
                    <HeaderFilter visible={true} />
                    <Scrolling mode="infinite" />
                    <Column dataField="CustomerName" caption="Customer Name" />
                    <Column dataField="Region" caption="Region" width={"100px"} />
                    <Column dataField="Quantity" caption="Quantity" width={"120px"} />
                    <Column dataField="AverageQty" caption="Quantity %" cellRender={chartCell} width={"120px"} />
                    <Column dataField="Value" caption="Value $" format={'$#,##0.##'} width={"100px"} />
                    <Column dataField="AverageValue" caption="Value %" cellRender={chartCell} width={"100px"} />

                    <Summary>
                        <TotalItem
                        column="Quantity"
                        summaryType="sum"
                        displayFormat="{0}"
                        />
                    </Summary>
                </DataGrid>
        </>
    );
}

export default UniqueCustomersGrid