import { Link } from '@mui/material';

const LinkCell = (cellData, action, params, attributes) => {
  const cellDataValue = attributes? attributes.value : cellData.value;
  return (
    <div>
      <Link 
        component="button"
        variant="body2"
        onClick={() => {
          return action(cellData, params)
        }}
        style={{color:"#626262", fontFamily:"verdana", fontSize:"9px"}}
      >
        {cellDataValue}
      </Link>
    </div>

  );
}
export default LinkCell;