import LinkCell from "../../grid/cellstyles/linkCell";
import { presetPopup } from "../../grid/dialog/presetPopup/presetPopup";
import { PurchaseOrderModal } from "../../grid/dialog/PurchaseOrder/PurchaseOrderModal";

export const getPurchaseOrderColumn = () => {
const {render: PurchaseOrderModalRender, openPopup: openPopupPurchaseOrder} = presetPopup({ Popup: PurchaseOrderModal });

  const openPurchaseOrderModal = (e) => {
    openPopupPurchaseOrder({internalId: e.row.data.InternalId, name: e.row.data.Name});
  }

  const columns = {};
  const localColumnKey = "col_poTotal";
  columns[localColumnKey] = {
    caption: "PO Total",
    localColumnKey: localColumnKey,
    dataField: "PO Total",
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => LinkCell(e, openPurchaseOrderModal),
    allowEditing: false,
    dataType: "number",
    width: 100
  };

  return {render: PurchaseOrderModalRender, colProps: columns};
}