import ArrowCircleDownOutlined from "@mui/icons-material/ArrowCircleDownOutlined";
import ArrowCircleUpOutlined from "@mui/icons-material/ArrowCircleUpOutlined";
import PauseCircleOutlineOutlined from "@mui/icons-material/PauseCircleOutlineOutlined";
import PropTypes from 'prop-types';

const IconComponent = ({ symbolClassName, children }) => (
  <div className="icon-container">
    <div className={symbolClassName}>
      {children}
    </div>
  </div>
);

export const setCellColorBackgroundFromValue = (row, dataField, limit) => {
  if (row.rowType === "data" && row.column.dataField === dataField) {
    if (row.data[dataField] > limit) {
      row.cellElement.style.backgroundColor = "pink";
    }
  }
}

const FlexContainer = ({ children }) => (
  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
    {children}
  </div>
);


const calculateWidth = (value) => {
  const percent = Math.abs(value * 100) / 3;
  return (25 * percent) / 100;
}

const Box = ({ color, width }) => (
  <div className={`box ${color}`} style={{ width: `${width}px` }} />
);

Box.propTypes = {
  color: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

export const renderL3MvsL12M = (data) => {
  const width = calculateWidth(data.value);
  const color = data.value < 0 ? 'red' : 'green';

  return (
    <FlexContainer>
      <div style={{ display: "flex" }}>
        <div style={{ width: `25px`, height: "22px", display: "flex", justifyContent: "space-between", borderLeftStyle: "none", borderTopStyle: "none", borderBottomStyle: "none", borderWidth: "0.5px", }}>
          {data.value !== 0 && <Box color={color} width={width} />}
        </div>
        <div style={{ width: `25px`, height: "22px", display: "flex", borderRightStyle: "none", borderTopStyle: "none", borderBottomStyle: "none", borderWidth: "0.5px" }}></div>
      </div>
      <div>{data.value}</div>
    </FlexContainer>
  );
}

export const rendery2y1 = (data) => {
  if (data.data.y2ToY1 === null || data.data.y2ToY1 === "") {
    return <></>
  }
  if (data.data.y2ToY1 === "Infinity") {
    return <>{data.text}</>
  }
  return <>{`${data.text}%`}</>
}

const ArrowIcon = ({ color }) => (
  <div style={{ display: "flex", justifyContent: "center" }}>
    <div>
      {color === '#F59595' ? <ArrowCircleDownOutlined style={{ color }} /> : <ArrowCircleUpOutlined style={{ color }} />}
    </div>
  </div>
);

ArrowIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export const renderYoyCell = (data) => {
  if (data.text) {
    if (data.text === "Descending") {
      return <ArrowIcon color='#F59595' />;
    }
    else if (data.text === "Equal") {
      return <div />;
    }
    return <ArrowIcon color='#93CC77' />;
  }
}

export const renderYoyStabilityCell = (data) => {
  const IconComponent = ({ color, children }) => (
    <div className="icon-container">
      <div style={{ color }}>
        {children}
      </div>
    </div>
  );

  switch (data.text) {
    case "Decreasing":
      return <IconComponent color="#F59595"><ArrowCircleDownOutlined /></IconComponent>;
    case "Equal":
      return <IconComponent color="#b7b7b7"><PauseCircleOutlineOutlined style={{ transform: 'rotate(90deg)' }} /></IconComponent>;
    default:
      return <IconComponent color="#93CC77"><ArrowCircleUpOutlined /></IconComponent>;
  }
}

export const renderIconQtyCell = (data, fieldName) => {


  if (data.text === "")
    return "";
  else {
    const qtyValue = +data.text;
    const qtySuggested = +data.row.data[fieldName]?? 0;
    const qtyDiff = qtyValue - qtySuggested;


    return (
      <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", marginLeft: "10px"}}>
        <>
          {
            (qtyDiff > 0) ?
              <IconComponent symbolClassName="increasing-qty"><ArrowCircleUpOutlined /></IconComponent>
            : (qtyDiff < 0) ?
                <IconComponent symbolClassName="decreasing-qty"><ArrowCircleDownOutlined /></IconComponent>
              :
                <IconComponent symbolClassName="equal-qty"><PauseCircleOutlineOutlined style={{ transform: 'rotate(90deg)' }} /></IconComponent>
          }
          {data.text}
        </>
      </div>
    );
  }
}

const BOUND_OFFSET = 0.99;
const TARGET_CODE_PREFIX = "A";

const getColor = (value, targetValue) => {
  const lowerBound = value - BOUND_OFFSET;
  const upperBound = value + BOUND_OFFSET;
  if (lowerBound > targetValue) {
    return "yellow";
  } else if (lowerBound <= targetValue && upperBound >= targetValue) {
    return "green";
  } else {
    return "red";
  }
};

const renderProjectedMoH = (data, settingsData) => {
  const categoryId = data.row.data.categoryId;
  const programId = data.row.data.programId;
  const categorySettings = settingsData.filter((x) => x.idCategory === categoryId && x.idProgram === programId);
  if (categorySettings) {
    if (isNaN(data.value)) {
      return <>{data.value}</>;
    }
    const value = Number(data.value);
    const target = categorySettings["codeLetter_"+TARGET_CODE_PREFIX];
    if (target) {
      const targetValue = Number(target.value);
      const color = getColor(value, targetValue);
      return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div className={`dot ${color}`} />
          {Number.isFinite(value)? value : " ∞ " }
        </div>
      );
    }
  }
};


export const renderProjectedMoHAfterL3M = (data, settingsData) => renderProjectedMoH(data, settingsData);
export const renderProjectedMoHAfterL12M = (data, settingsData) => renderProjectedMoH(data, settingsData);
