import { ExcludedItemsGrid } from "./ExcludedItemsGrid";
import ExcludedItemsByYear from "./ExcludedItemsByYear";
import { ExcludedItemsL3m12m } from "./ExcludedItemsL3m12m";

export const ExcludedItemsTab = ({data}) => {

        return (
            <div id="container" style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <div style={{ display: "flex", flexDirection: "row", height: "50%" }}>
                    <div style={{ padding: "2px", width: "50%" }}><ExcludedItemsL3m12m dataSource={data}/></div>
                    <div style={{ padding: "2px", width: "50%" }}><ExcludedItemsByYear dataSource={data?.excludedItemsByYear}/></div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", height: "50%" }}>
                    <div style={{ padding: "2px", width: "100%" }}><ExcludedItemsGrid dataSource={data?.data} /></div>
                </div>
            </div>
        );
}
export default ExcludedItemsTab;