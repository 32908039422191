import LinkCell from "../../grid/cellstyles/linkCell";
import { presetPopup } from "../../grid/dialog/presetPopup/presetPopup";
import { KitsModal } from "../../grid/dialog/Kits/kitsModal";

export const getCurrentSystemsColumn = () => {
const {render: ActiveKitsModalRender, openPopup: openPopupActiveKits} = presetPopup({ Popup: KitsModal });

  const openActiveKitsModal = (e) => {
    openPopupActiveKits({internalId: e.row.data.InternalId, name: e.row.data.Name});
  }

  const localColumnKey = "col_activeKits";
  const column = {
    caption: "Current Systems",
    localColumnKey: localColumnKey,
    dataField: 'Active Parents',
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => LinkCell(e, openActiveKitsModal),
    allowEditing: false,
    dataType: "number",
    width: 100
  };

  return {render: ActiveKitsModalRender, colProps: column};
}