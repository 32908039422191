import { useCallback, useState } from "react";
import { Button } from "devextreme-react";
import { CheckBox } from "devextreme-react/check-box";
import { useForm } from "react-hook-form";

const GridSettingsContent = ({ onSubmit, ...gridSettings }) => {

    const { handleSubmit } = useForm();

    const [columnLines, setColumnLines] = useState(gridSettings.columnLines);
    const [rowLines, setRowLines] = useState(gridSettings.rowLines);
    const [borders, setBorders] = useState(gridSettings.borders);
    const [rowAlternation, setRowAlternation] = useState(gridSettings.rowAlternation);

    const onShowColumnLinesValueChanged = useCallback((e) => {
        setColumnLines(e.value);
    }, []);

    const onShowRowLinesValueChanged = useCallback((e) => {
        setRowLines(e.value);
    }, []);

    const onShowBordersValueChanged = useCallback((e) => {
        setBorders(e.value);
    }, []);

    const onRowAlternationEnabledChanged = useCallback((e) => {
        setRowAlternation(e.value);
    }, []);
    
    const onSubmitForm = () => {
        onSubmit({columnLines, rowLines, borders, rowAlternation});
    };

    return (
        <div className="dx-fieldset">
            <form onSubmit={handleSubmit(onSubmitForm)}>
                <div className="dx-field" key={"columnLinesOption"}>
                    <div className="dx-field-label" style={{ width: "fit-content" }}>
                      Show Column Lines
                    </div>
                    <div className="dx-field-value" style={{ display: "inline-flex", width: "30%" }}>
                        <CheckBox
                            readOnly={false}
                            name={"showColumnLines"}
                            value={columnLines}
                            onValueChanged={(e) => onShowColumnLinesValueChanged(e)}
                            key={"columnLinesOption_cbx"}
                        />
                    </div>
                </div>
                <div className="dx-field" key={"rowLinesOption"}>
                    <div className="dx-field-label" style={{ width: "fit-content" }}>
                        Show Row Lines
                    </div>
                    <div className="dx-field-value" style={{ display: "inline-flex", width: "30%" }}>
                        <CheckBox
                            readOnly={false}
                            name={"showRowLines"}
                            value={rowLines}
                            onValueChanged={(e) => onShowRowLinesValueChanged(e)}
                            key={"rowLinesOption_cbx"}
                        />
                    </div>
                </div>
                <div className="dx-field" key={"borderOption"}>
                    <div className="dx-field-label" style={{ width: "fit-content" }}>
                        Show Borders
                    </div>
                    <div className="dx-field-value" style={{ display: "inline-flex", width: "30%" }}>
                        <CheckBox
                            readOnly={false}
                            name={"showBorders"}
                            value={borders}
                            onValueChanged={(e) => onShowBordersValueChanged(e)}
                            key={"borderOption_cbx"}
                        />
                    </div>
                </div>
                <div className="dx-field" key={"rowAlternation"}>
                    <div className="dx-field-label" style={{ width: "fit-content" }}>
                        Alternating Row Color
                    </div>
                    <div className="dx-field-value" style={{ display: "inline-flex", width: "30%" }}>
                        <CheckBox
                            readOnly={false}
                            name={"rowAlternation"}
                            value={rowAlternation}
                            onValueChanged={(e) => onRowAlternationEnabledChanged(e)}
                            key={"rowAlternation_cbx"}
                        />
                    </div>
                </div>
                <div>
                    <Button
                        width={120}
                        text="Save"
                        type="normal"
                        stylingMode="contained"
                        onClick={onSubmitForm}
                    />
                </div>
            </form>
        </div>
    );
};

export default GridSettingsContent;
