import { NumberBox, SelectBox, TextBox } from "devextreme-react";
import TagBox from 'devextreme-react/tag-box';
import RadioGroup from 'devextreme-react/radio-group';
import { fetcher } from "../../actions";
import useSWR from "swr";
import { Delete } from "@mui/icons-material";
import IconButton from '@mui/material/IconButton';
import { textOptions, numericOptions, columnsValueOptions, logicalOperatorOptions } from "./ColumnOptions"
import { useEffect, useState } from "react";
import { SearchSelectBox } from "./SearchSelectBox";
import { filter } from "lodash";

export const ColumnsFilter = (props) => {

    const { filterRows, setFilterRows, setFilterName } = props;

    const { data: dataSource } = useSWR(`/api/RoleColumns/reportColumns`, fetcher);

    const preferredVendorRow = filterRows?.filter((row) => row.id === 5);
    const universalProductTypeRow = filterRows?.filter((row) => row.id === 6);
    const topBuyerNameRow = filterRows?.filter((row) => row.id === 94);
    const programTypeRow = filterRows?.filter((row) => row.id === 91);
    const categoryTypeRow = filterRows?.filter((row) => row.id === 92);
    const shouldFetchPreferredVendor = preferredVendorRow[0]?.operator === 9 || preferredVendorRow[0]?.operator === 10;
    const shouldFetchTopBuyerName = topBuyerNameRow[0]?.operator === 9 || topBuyerNameRow[0]?.operator === 10;
    const shouldFetchUniversalProductType = universalProductTypeRow[0]?.operator === 9 || universalProductTypeRow[0]?.operator === 10;
    const shouldFetchPrograms = programTypeRow[0]?.operator === 9 || programTypeRow[0]?.operator === 10;
    const shouldFetchCategories = categoryTypeRow[0]?.operator === 9 || categoryTypeRow[0]?.operator === 10;

    const { data: preferredVendorValues } = useSWR(shouldFetchPreferredVendor ? "/api/ValidateItems/GetColumnsItems?columnName=PreferredVendor" : null, fetcher);
    const { data: UniversalProductTypeValues } = useSWR(shouldFetchUniversalProductType ? "/api/ValidateItems/GetColumnsItems?columnName=universalProductType" : null, fetcher);
    const { data: TopBuyerNameValues } = useSWR(shouldFetchTopBuyerName ? "/api/ValidateItems/GetColumnsItems?columnName=TopBuyerName" : null, fetcher);
    const { data: ProgramValues } = useSWR(shouldFetchPrograms ? "/api/Programs" : null, fetcher);
    const { data: CategoryValues } = useSWR(shouldFetchCategories ? "/api/Categories" : null, fetcher);



    const emptySearch = [
        {
            "id": 0,
            "column": null,
            "columnType": null,
            "operator": null,
            "operatorName": null,
            "filterValue": null,
            "filterValue2": null,
            "logicalOperator": null
        }
    ];




    const preferredVendorOptions = preferredVendorValues?.map((x, index) => {
        return {
            id: index,
            name: x.list,
        }
    });

    const universalProductTypeOptions = UniversalProductTypeValues?.map((x, index) => {
        return {
            id: index,
            name: x.list,
        }
    });

    const TopBuyerNameOptions = TopBuyerNameValues?.map((x, index) => {
        return {
            id: index,
            name: x.list,
        }
    });

    const columnOptions = dataSource?.filter(column => column.NameReport !== null && column.GridId === 1 &&
        column.NameColumn !== "Exclusions")?.map(x => {
            return {
                id: x.Id,
                name: x.NameColumn,
                columnType: x.ColumnType,
            }
        });

    const programOptions = ProgramValues?.map((x) => {
        return {
            id: x.programId,
            name: x.name,
        }
    });

    const categoryOptions = CategoryValues?.map((x) => {
        return {
            id: x.categoryId,
            name: x.categoryName,
        }
    });

    const handleColumnChange = (e, index) => {
        if (e.event !== undefined && e.value !== null) {
            const newRows = [...filterRows];
            newRows[index].id = e.value;
            newRows[index].column = columnOptions.find(
                a => a.id === e.value
            )?.name;
            newRows[index].columnType = columnOptions.find(
                a => a.id === e.value
            )?.columnType
            setFilterRows(newRows);
        }
    }

    const handleOperatorChange = ({ event, value }, index) => {
        if (event !== undefined && value !== null) {
            const newRows = [...filterRows];
            newRows[index].operator = value;

            const specialIds = [5, 6, 91, 92];
            const selectedOption = newRows[index].columnType === 'numeric'
                ? numericOptions.find(a => a.id === value)
                : (specialIds.includes(newRows[index].id)
                    ? [...textOptions, ...columnsValueOptions].find(a => a.id === value)
                    : textOptions.find(a => a.id === value));

            const fullValue = selectedOption?.name;
            newRows[index].operatorName = fullValue?.slice(fullValue?.indexOf(" ") + 1);

            setFilterRows(newRows);
        }
    };

    const handleFilterValueChange = (e, index) => {
        if (e.event !== undefined && e.value !== null) {
            const newRows = [...filterRows];
            newRows[index].filterValue = e.component._changedValue;
            setFilterRows(newRows);
        }
    }

    const handleFilterSelectValueChange = (e, index) => {
        let filterValue = e.value;
        if (Array.isArray(e.value)) {
            filterValue = e.value.join(",");
        }

        if (filterValue !== null) {
            const newRows = [...filterRows];
            const previousValue = newRows[index].filterValue;
            if (previousValue !== filterValue) {
                newRows[index].filterValue = filterValue;
                setFilterRows(newRows);
            }
        }
    }

    const handleFilterValue2Change = (e, index) => {
        if (e.event !== undefined && e.value !== null) {
            const newRows = [...filterRows];
            newRows[index].filterValue2 = e.component._changedValue;
            console.log(newRows);
            setFilterRows(newRows);
        }
    }

    const handleFilterRowDelete = (index) => {
        const newRows = [...filterRows];
        if (index === newRows?.length - 1 && newRows?.length > 1) {
            newRows[newRows?.length - 2].logicalOperator = null;
        }
        newRows.splice(index, 1);
        console.log(newRows);
        setFilterRows(newRows);
    }

    const handleLogicalOperatorChange = (e, index) => {

        if (e.event !== undefined && e.value !== null) {
            const newRows = [...filterRows];
            if (newRows[index].logicalOperator === null) {
                newRows.push({
                    id: 0,
                    column: null,
                    columnType: null,
                    operator: null,
                    operatorName: null,
                    filterValue: null,
                    filterValue2: null,
                    logicalOperator: null,
                });
            }
            newRows[index].logicalOperator = e.value;
            console.log(newRows);
            setFilterRows(newRows);
        }
    }

    const getOptionsToSelect = (columnType, columnId) => {
        const SPECIAL_COLUMN_IDS = [5, 6, 91, 92, 94];
        if (columnType === 'numeric') {
            return numericOptions;
        } else if (SPECIAL_COLUMN_IDS.includes(columnId)) {
            return [...textOptions, ...columnsValueOptions];
        } else {
            return textOptions;
        }
    };

    const renderNumericField = (operator, filterValue, filterValue2, index) => {
        if (operator === 9) {
            return (
                <div style={{ display: "flex", flexDirection: "row", marginRight: "0.5rem" }}>
                    <div style={{ paddingRight: "0.5rem" }}>
                        <NumberBox
                            onChange={(e) => handleFilterValue2Change(e, index)}
                            placeholder="Add Filter Value"
                            value={filterValue2}
                        >
                        </NumberBox>
                    </div>
                    <div >
                        <NumberBox
                            onChange={(e) => handleFilterValueChange(e, index)}
                            placeholder="Add Filter Value"
                            value={filterValue}
                        >
                        </NumberBox>
                    </div>
                </div >
            )
        }
        else {
            return (
                <div >
                    <NumberBox
                        onChange={(e) => handleFilterValueChange(e, index)}
                        placeholder="Add Filter Value"
                        value={filterValue}
                    >
                    </NumberBox>
                </div>
            )
        }
    }

    const renderStringField = (id, operator, filterValue, index) => {
        let columnOptions = null;
        if (operator === 9 || operator === 10) {
            switch (id) {
                case 5:
                    columnOptions = preferredVendorOptions;
                    break;
                case 6:
                    columnOptions = universalProductTypeOptions;
                    break;
                case 91:
                    columnOptions = programOptions;
                    break;
                case 92:
                    columnOptions = categoryOptions;
                    break;
                case 94:
                    columnOptions = TopBuyerNameOptions;
                    break;
                default:
                    columnOptions = null;
            }

            const initialFilterValue = filterValue?.split(","); 

            return (
                <div style={{ display: "flex", height: '34px' }}>
                    <TagBox
                        width={'400px'}
                        placeholder="Select Filter Value "
                        dataSource={columnOptions}
                        displayExpr="name"
                        valueExpr="name"
                        value={initialFilterValue}
                        multiline={false}
                        onValueChanged={(e) => handleFilterSelectValueChange(e, index)}
                    />
                </div>
            )
        }
        else {
            return (
                <TextBox
                    onChange={(e) => handleFilterValueChange(e, index)}
                    placeholder="Add Filter Value"
                    value={filterValue}
                >
                </TextBox >
            )
        }
    }

    const handleSelectedSearchChange = (selectedSearchName, selectedSearch) => {
        if (selectedSearchName === null) {
            setFilterRows(emptySearch);
            setFilterName(null);
            return;
        }
        setFilterRows(selectedSearch);
        setFilterName(selectedSearchName);
    };

    return (
        <>
            <div>
                <SearchSelectBox
                    type={"column"}
                    onSelectedSearchChange={handleSelectedSearchChange}
                />
            </div>

            <div>
                {filterRows?.map((filterRow, index) => (
                    <div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: "2rem", paddingBottom: "2rem", paddingLeft: "0.5rem", paddingRight: "1rem", flexGrow: "1", flexBasis: "0" }}>
                            <div style={{ display: "flex" }}>
                                <SelectBox
                                    placeholder="Select Column to Filter "
                                    dataSource={columnOptions}
                                    displayExpr="name"
                                    valueExpr="id"
                                    value={filterRow?.id}
                                    onValueChanged={(e) => handleColumnChange(e, index)}
                                    id="column"

                                />
                            </div>
                            <div >
                                <SelectBox
                                    placeholder="Select Logical operator "
                                    dataSource={getOptionsToSelect(filterRow?.columnType, filterRow?.id)}
                                    displayExpr="name"
                                    valueExpr="id"
                                    value={filterRow?.operator}
                                    onValueChanged={(e) => handleOperatorChange(e, index)}
                                    id="operator"

                                />
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", marginRight: "0.5rem" }}>
                                {filterRow?.columnType === 'numeric' ?
                                    renderNumericField(filterRow?.operator, filterRow?.filterValue, filterRow?.filterValue2, index) :
                                    renderStringField(filterRow?.id, filterRow?.operator, filterRow?.filterValue, index)}
                            </div>
                            <div style={{ marginTop: "0.4rem" }}>
                                <RadioGroup items={logicalOperatorOptions} value={filterRow?.logicalOperator} onValueChanged={(e) => handleLogicalOperatorChange(e, index)} valueExpr="id" layout="horizontal" />
                            </div>
                            {(index !== 0 || filterRows.length > 1) &&
                                <IconButton id={index} onClick={() => handleFilterRowDelete(index)}>
                                    <Delete fontSize="small" />
                                </IconButton>
                            }
                        </div>
                        {
                            index < filterRows.length - 1 &&
                            <div>
                                <div
                                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: "1rem" }}
                                >
                                    <div style={{ flex: 1, height: '1px' }} />

                                    <div>
                                        <p style={{ width: '70px', textAlign: 'center' }}>{logicalOperatorOptions.find(
                                            a => a.id === filterRow?.logicalOperator
                                        )?.text}</p>
                                    </div>

                                    <div style={{ flex: 1, height: '1px' }} />
                                </div>
                            </div>
                        }
                    </div>
                ))
                }
            </div >
        </>
    );
}
