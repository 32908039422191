import LinkCell from "../../grid/cellstyles/linkCell";
import { presetPopup } from "../../grid/dialog/presetPopup/presetPopup";
import { BackModal } from "../../grid/dialog/Back/BackModal";

export const getBackOrderAndCommittedColumns = () => {
  const {render: BackModalRender, openPopup: openPopupBackOrderAndCommitted} = presetPopup({ Popup: BackModal });

  const openBackModal = (e) => {
    openPopupBackOrderAndCommitted({internalId: e.row.data.InternalId, name: e.row.data.Name});
  }
  
  const columns = {};
  let localColumnKey = "col_commitTotal";
  columns[localColumnKey] = {
    caption: "Commit Total",
    localColumnKey: localColumnKey,
    dataField: "CommitTotal",
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => LinkCell(e, openBackModal),
    allowEditing: false,
    dataType: "number",
    width: 100
  };

  localColumnKey = "col_boTotal";
  columns[localColumnKey] = {
    caption: "BO Total",
    localColumnKey: localColumnKey,
    dataField: "BO_total",
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => LinkCell(e, openBackModal),
    allowEditing: false,
    dataType: "number",
    width: 100
  };

  return {render: BackModalRender, colProps: columns};
}