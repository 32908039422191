import { SelectBox } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { fetcher } from '../../actions';


export const SearchSelectBox = ({ type, onSelectedSearchChange }) => {
    const { data, isLoading, isError } = useSWR(`/api/SavedSearch/${type}/all`, fetcher);
    const [searches, setSearches] = useState([]);

    const handleSelectedSearchChange = ({ value }) => {
        if (value === null) {
            onSelectedSearchChange(null, null);
            return;
        }
        const templateId = value;
        const selectedSearchName = searches.find(a => a.id === templateId)?.name;
        const selectedSearch = searches.find(a => a.id === templateId)?.query;
        onSelectedSearchChange(selectedSearchName, selectedSearch);
    };



    useEffect(() => {
        let records = [];
        if (data) {
            data.map((search) => {
                records.push({
                    id: search.id,
                    name: search.searchName,
                    query: JSON.parse(search.query)
                });
            });
        }
        setSearches(records);
    }, [data]);
    return (
        <>
            <SelectBox
                searchEnabled={true}
                items={searches}
                placeholder="Saved Templates"
                showClearButton={true}
                valueExpr="id"
                displayExpr="name"
                onValueChanged={(e) => handleSelectedSearchChange(e)}
            />
        </>
    );

}


