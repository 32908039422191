import { useState, useEffect, useCallback } from "react";

export const QuickPopup = ({ Popup, popupId, openHandler, closeHandler, toggleHandler, onClose, ...staticProps }) => {
  const [showModal, setShowModal] = useState(false);
  const [dynamicProps, setDynamicProps] = useState({});
  const closePopup = () => {setShowModal(false)};
  const openPopup = useCallback((componentProps) => {
    setDynamicProps(componentProps??{});
    setShowModal(true);
  },[]);
  const togglePopup = useCallback((componentProps) => {
    setDynamicProps(componentProps??{});
    setShowModal(prev=>!prev);
  },[]);
  const specialClosePopup = () => {
    if (onClose instanceof Function) onClose();
    closePopup();
  };
  useEffect(()=> {
  if (openHandler instanceof Function) openHandler(openPopup, popupId);
  if (closeHandler instanceof Function) closeHandler(closePopup, popupId);
  if (toggleHandler instanceof Function) toggleHandler(togglePopup);
  }, [openHandler, closeHandler, toggleHandler, popupId, openPopup, togglePopup]);
  return (
    <>
      {(showModal) && <Popup open={showModal} onClose={specialClosePopup} {...staticProps} {...dynamicProps} />}
    </>
  );
};