import LinkCell from "../../grid/cellstyles/linkCell";
import { presetPopup } from "../../grid/dialog/presetPopup/presetPopup";
import { VendorsModal } from "../../grid/dialog/Vendors/VendorsModal";

export const getPreferredVendorColumn = () => {
const {render: PreferredVendorModalRender, openPopup: openPopupPreferredVendor} = presetPopup({ Popup: VendorsModal });

  const openPreferredVendorModal = (e) => {
    openPopupPreferredVendor({internalId: e.row.data.InternalId, name: e.row.data.Name});
  }

  const localColumnKey = "col_preferredVendor";
  const column = {
    caption: "Preferred Vendor",
    localColumnKey: localColumnKey,
    dataField: "Preferred Vendor",
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => LinkCell(e, openPreferredVendorModal),
    allowEditing: false,
    dataType: "string",
    width: 100
  };

  return {render: PreferredVendorModalRender, colProps: column};
}