import { Label, Scale } from "@mui/icons-material";
import { CircularGauge } from "devextreme-react";
import { Geometry, ValueIndicator, Animation } from "devextreme-react/cjs/circular-gauge";
import { useState } from 'react';
import "./TopBuyerShareChart.css";

export const TopBuyerShareChart = (props) => {
    const topBuyerShare = Number.parseFloat(props.topBuyerShare).toFixed(1);
    const divCircularGraphId = "textCloud";
    const [isSmallScreenSize, setIsSmallScreenSize] = useState(false);
 
    function handleResize() {
        const divCircularGraphObj = document.getElementById(divCircularGraphId);
        if (divCircularGraphObj) {
            const isSmall = (divCircularGraphObj.offsetWidth < 220)? true : false;
            setIsSmallScreenSize(isSmall);
        }
    }
   
    const customizeText = ({ valueText }) => {
        return `${valueText} %`;
    };

    const customTicks = [0, 20, 40, 60, 80, 100];

    return (
        <>
            <div className="header-Text">
                Top Buyer Share:
            </div>
            {isSmallScreenSize? (
                <div className="percent-Text">
                    {topBuyerShare}%
                </div>
                ) : (
                    <CircularGauge
                        id={divCircularGraphId}
                        value={topBuyerShare}
                        height={"79%"}
                        onDrawn={handleResize}
                    >
                        <ValueIndicator
                            type="textCloud"
                            color="#483D8B"
                            customizeText={customizeText}
                        ></ValueIndicator>
                        <Scale startValue={0} endValue={100} tickInterval={20} customTicks={customTicks}>
                            <Label customizeText={customizeText}></Label>
                        </Scale>
                        <Geometry startAngle={180} endAngle={0}></Geometry>
                        <Animation
                          enabled={false}
                        />
                    </CircularGauge>
                )
            }
        </>
    )
}
