import { Button, Tooltip } from "devextreme-react";
import { Popup, Position } from 'devextreme-react/popup';
import { CheckBox } from "devextreme-react/check-box";
import { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { initColumnSum } from '../../../SumByColumn/SumByColumn';
import { alert } from 'devextreme/ui/dialog';
import './sumColumnsModal.css';

export const SumColumnsModal = ({ getSumByColumn, onClose, refSumByColDisplay }) => {
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [showColumnsSelector, setShowColumnsSelector] = useState(false);
  const activeSumColumnInitialState = useMemo(()=> {return initColumnSum(true)},[]);
  const [activeSumColumn, setActiveSumColumn] = useState(activeSumColumnInitialState);
  const [popupHeight, setPopupHeight] = useState(470);
  const refInitialSumColumnByLocalStorage = useRef(null);

  const sumColumnsKey = "sumColumnsEnabled";

  const getPopupAttributes = useCallback( (columnList) =>{
    let countActiveColumns = 0;
    Object.keys(columnList).forEach((key)=>{
        if (columnList[key]) countActiveColumns++;
    });
    const highPopup = (countActiveColumns * 38) + 100 + (countActiveColumns<5? 10:0);
    return {highPopup, countActiveColumns};
  },[]);

  useEffect(()=> {
    refInitialSumColumnByLocalStorage.current = JSON.parse(localStorage.getItem(sumColumnsKey))?? activeSumColumnInitialState;
    const {highPopup} = getPopupAttributes(refInitialSumColumnByLocalStorage.current);
    setPopupHeight(highPopup);
    setActiveSumColumn(refInitialSumColumnByLocalStorage.current);
    setIsLoadingData(false);
    },
    [getPopupAttributes, activeSumColumnInitialState]
  );

  const showColumnSumSelector = () => {
    setShowColumnsSelector(true);
  };

  const hideColumnSumSelector = () => {
    setShowColumnsSelector(false);
  };

  const onChangeActiveSumColumn = (e, chk) => {
      const isActive = e.value;
      setActiveSumColumn((prev) => {return { ...prev, [chk]: isActive }});
  }

  const onSaveSelection = () => {
    const {highPopup, countActiveColumns} = getPopupAttributes(activeSumColumn);
    if (countActiveColumns === 0) {
        alert("At least one column must be selected.", "Message");
        return;
    }
    localStorage.setItem(sumColumnsKey, JSON.stringify(activeSumColumn));
    refInitialSumColumnByLocalStorage.current = activeSumColumn;
    setPopupHeight(highPopup);
    setShowColumnsSelector(false);
  };

  const onCancelSelection = () => {
      setActiveSumColumn((prev)=> {return {...prev, ...refInitialSumColumnByLocalStorage.current}});
      const {highPopup} = getPopupAttributes(refInitialSumColumnByLocalStorage.current);
      setPopupHeight(highPopup);
      setShowColumnsSelector(false);
  }

  if (isLoadingData) {
    return (<></>)
  }
  
  return (
    <>
      <Popup
        id="SumColumnsPopup"
        visible={true}
        onHiding={onClose}
        showTitle={true}
        title={"Sum of columns"}
        width={"340px"}
        height={popupHeight+"px"}
        resizeEnabled={true}
        shading={false}
      >
        <Position at="bottom right" collision="fit" boundaryOffset="50 70" />
        <div style={{marginTop: '-5px', textAlign: 'right'}}>
            <Button icon={"checklist"} onClick={showColumnSumSelector} id={"columnSumSelector"} className="mini-button" />                
            <Tooltip
                target={`#${"columnSumSelector"}`}
                showEvent="mouseenter"
                hideEvent="mouseleave"
                hideOnOutsideClick={false}
            >
                <div>{'Column selector'}</div>
            </Tooltip>
        </div>
        <div>
          {(activeSumColumn['BO_total']) && 
            <div className="dx-field" style={{margin: "0px"}} key={"BO_total"}>
              <div className="dx-field-label" style={{ width: "fit-content" }}>
                BO Total
              </div>
              <div className="dx-field-value" style={{ display: "inline-flex", width: "40%" }}>
                  <input type="text" className="dx-texteditor-input" style={{textAlign: "right", width: "127px"}} 
                  id="popupSum_BO_total" 
                  ref={obj => refSumByColDisplay.current["BO_total"] = obj} 
                  value={getSumByColumn("BO_total")}
                  readOnly
                  />
              </div>
            </div>
          }
          {(activeSumColumn['CommitTotal']) && 
            <div className="dx-field" style={{margin: "0px"}} key={"CommitTotal"}>
              <div className="dx-field-label" style={{ width: "fit-content" }}>
                  Committed Total
              </div>
              <div className="dx-field-value" style={{ display: "inline-flex", width: "40%" }}>
                  <input type="text" className="dx-texteditor-input" style={{textAlign: "right", width: "127px"}} 
                  id="popupSum_CommitTotal" 
                  ref={obj => refSumByColDisplay.current["CommitTotal"] = obj} 
                  value={getSumByColumn("CommitTotal")}
                  readOnly
                  />
              </div>
            </div>
          }
          {(activeSumColumn['Suggested PO Qty']) && 
            <div className="dx-field" style={{margin: "0px"}} key={"Suggested_PO_Qty"}>
              <div className="dx-field-label" style={{ width: "fit-content" }}>
                Suggested PO Qty
              </div>
              <div className="dx-field-value" style={{ display: "inline-flex", width: "40%" }}>
                  <input type="text" className="dx-texteditor-input" style={{textAlign: "right", width: "127px"}} 
                  id="popupSum_Suggested_PO_Qty" 
                  ref={obj => refSumByColDisplay.current["Suggested PO Qty"] = obj} 
                  value={getSumByColumn("Suggested PO Qty")}
                  readOnly
                  />
              </div>
            </div>
          }
          {(activeSumColumn['poQtyReviewer']) && 
            <div className="dx-field" style={{margin: "0px"}} key={"poQtyReviewer"}>
              <div className="dx-field-label" style={{ width: "fit-content" }}>
                PO Qty Reviewer
              </div>
              <div className="dx-field-value" style={{ display: "inline-flex", width: "40%" }}>
                  <input type="text" className="dx-texteditor-input" style={{textAlign: "right", width: "127px"}} 
                  id="popupSum_poQtyReviewer" 
                  ref={obj => refSumByColDisplay.current["poQtyReviewer"] = obj} 
                  value={getSumByColumn("poQtyReviewer")}
                  readOnly
                  />
              </div>
            </div>
          }
          {(activeSumColumn['poQtyApprover']) && 
            <div className="dx-field" style={{margin: "0px"}} key={"poQtyApprover"}>
              <div className="dx-field-label" style={{ width: "fit-content" }}>
                PO Qty Approver
              </div>
              <div className="dx-field-value" style={{ display: "inline-flex", width: "40%" }}>
                  <input type="text" className="dx-texteditor-input" style={{textAlign: "right", width: "127px"}} 
                  id="popupSum_poQtyApprover" 
                  ref={obj => refSumByColDisplay.current["poQtyApprover"] = obj} 
                  value={getSumByColumn("poQtyApprover")}
                  readOnly
                  />
              </div>
            </div>
          }
          {(activeSumColumn['poQtyMarket']) && 
            <div className="dx-field" style={{margin: "0px"}} key={"poQtyMarket"}>
              <div className="dx-field-label" style={{ width: "fit-content" }}>
                PO Qty Market
              </div>
              <div className="dx-field-value" style={{ display: "inline-flex", width: "40%" }}>
                  <input type="text" className="dx-texteditor-input" style={{textAlign: "right", width: "127px"}}
                  id="popupSum_poQtyMarket"
                  ref={obj => refSumByColDisplay.current["poQtyMarket"] = obj}
                  value={getSumByColumn("poQtyMarket")}
                  readOnly
                  />
              </div>
            </div>
          }
          {(activeSumColumn['CostSuggested']) && 
            <div className="dx-field" style={{margin: "0px"}} key={"CostSuggested"}>
              <div className="dx-field-label" style={{ width: "fit-content" }}>
                Suggested PO Cost
              </div>
              <div className="dx-field-value" style={{ display: "inline-flex", width: "40%" }}>
                  <span style={{textAlign: "left", marginTop: "7px"}}>$</span>
                  <span>
                    <input type="text" className="dx-texteditor-input" style={{textAlign: "right", width: "120px"}} 
                    id="popupSum_CostSuggested" 
                    ref={obj => refSumByColDisplay.current["CostSuggested"] = obj} 
                    value={getSumByColumn("CostSuggested")}
                    readOnly
                    />
                  </span>
              </div>
            </div>
          }
          {(activeSumColumn['CostApprover']) && 
            <div className="dx-field" style={{margin: "0px"}} key={"CostApprover"}>
              <div className="dx-field-label" style={{ width: "fit-content" }}>
                Approver PO Cost
              </div>
              <div className="dx-field-value" style={{ display: "inline-flex", width: "40%" }}>
                  <span style={{textAlign: "left", marginTop: "7px"}}>$</span>
                  <span>
                    <input type="text" className="dx-texteditor-input" style={{textAlign: "right", width: "120px"}} 
                    id="popupSum_CostApprover" 
                    ref={obj => refSumByColDisplay.current["CostApprover"] = obj} 
                    value={getSumByColumn("CostApprover")}
                    readOnly
                    />
                  </span>
              </div>
            </div>
          }
          {(activeSumColumn['CostReviewer']) && 
            <div className="dx-field" style={{margin: "0px"}} key={"CostReviewer"}>
              <div className="dx-field-label" style={{ width: "fit-content" }}>
                Reviewer PO Cost
              </div>
              <div className="dx-field-value" style={{ display: "inline-flex", width: "40%" }}>
                <span style={{textAlign: "left", marginTop: "7px"}}>$</span>
                <span>
                  <input type="text" className="dx-texteditor-input" style={{textAlign: "right", width: "120px"}} 
                  id="popupSum_CostReviewer" 
                  ref={obj => refSumByColDisplay.current["CostReviewer"] = obj} 
                  value={getSumByColumn("CostReviewer")}
                  readOnly
                  />
                </span>
              </div>
            </div>
          }
          {(activeSumColumn['CostMarket']) && 
            <div className="dx-field" style={{margin: "0px"}} key={"CostMarket"}>
              <div className="dx-field-label" style={{ width: "fit-content" }}>
                Market PO Cost
              </div>
              <div className="dx-field-value" style={{ display: "inline-flex", width: "40%" }}>
                <span style={{textAlign: "left", marginTop: "7px"}}>$</span>
                <span>
                  <input type="text" className="dx-texteditor-input" style={{textAlign: "right", width: "120px"}} 
                  id="popupSum_CostMarket" 
                  ref={obj => refSumByColDisplay.current["CostMarket"] = obj} 
                  value={getSumByColumn("CostMarket")}
                  readOnly
                  />
                </span>
              </div>
            </div>
          }
        </div>
      </Popup>
      <Popup
        id="ColumnsPopup"
        visible={showColumnsSelector}
        showTitle={false}
        onHiding={hideColumnSumSelector}
        width={"220px"}
        height={"440px"}
        resizeEnabled={true}
        shading={true}
        hideOnOutsideClick={true}
        >
          <Position of={`#${"columnSumSelector"}`} my="top" collision="fit" />
          <div>
            <div className="dx-field" style={{margin: "0px"}} key={"chkLab_BO_total"}>
              <div className="dx-field-label" style={{ width: "fit-content", padding: "0px" }}>
                <CheckBox id='chk_BO_total' className="mini-checkbox" text="BO Total"
                          value={activeSumColumn['BO_total']}
                          onValueChanged={(e) => onChangeActiveSumColumn( e,'BO_total' )}
                          key={"BO_total_cbx"}
                          />
              </div>
            </div>
            <div className="dx-field" style={{margin: "0px"}} key={"chkLab_CommitTotal"}>
              <div className="dx-field-label" style={{ width: "fit-content", padding: "0px" }}>
                <CheckBox id='chk_CommitTotal' className="mini-checkbox" text="Commit Total"
                          value={activeSumColumn['CommitTotal']}
                          onValueChanged={(e) => onChangeActiveSumColumn( e,'CommitTotal' )}
                          key={"CommitTotal_cbx"}
                          />
              </div>
            </div>
            <div className="dx-field" style={{margin: "0px"}} key={"chkLab_SuggestedPoQty"}>
              <div className="dx-field-label" style={{ width: "fit-content", padding: "0px" }}>
                <CheckBox id='chk_SuggestedPoQty' className="mini-checkbox" text="Suggested Po Qty"
                          value={activeSumColumn['Suggested PO Qty']}
                          onValueChanged={(e) => onChangeActiveSumColumn( e,'Suggested PO Qty' )}
                          key={"SuggestedPOQty_cbx"}
                          />
              </div>
            </div>
            <div className="dx-field" style={{margin: "0px"}} key={"chkLab_QtyReviewer"}>
              <div className="dx-field-label" style={{ width: "fit-content", padding: "0px" }}>
                <CheckBox id='chk_QtyReviewer' className="mini-checkbox" text="PO Qty Reviewer"
                          value={activeSumColumn['poQtyReviewer']}
                          onValueChanged={(e) => onChangeActiveSumColumn( e,'poQtyReviewer' )}
                          key={"poQtyReviewer_cbx"}
                          />
              </div>
            </div>
            <div className="dx-field" style={{margin: "0px"}} key={"chkLab_QtyApprover"}>
              <div className="dx-field-label" style={{ width: "fit-content", padding: "0px" }}>
                <CheckBox id='chk_QtyApprover' className="mini-checkbox" text="PO Qty Approver"
                          value={activeSumColumn['poQtyApprover']}
                          onValueChanged={(e) => onChangeActiveSumColumn( e,'poQtyApprover' )}
                          key={"poQtyApprover_cbx"}
                          />
              </div>
            </div>
            <div className="dx-field" style={{margin: "0px"}} key={"chkLab_QtyMarket"}>
              <div className="dx-field-label" style={{ width: "fit-content", padding: "0px" }}>
                <CheckBox id='chk_QtyMarket' className="mini-checkbox" text="PO Qty Market"
                          value={activeSumColumn['poQtyMarket']}
                          onValueChanged={(e) => onChangeActiveSumColumn( e,'poQtyMarket' )}
                          key={"poQtyMarket_cbx"}
                          />
              </div>
            </div>
            <div className="dx-field" style={{margin: "0px"}} key={"chkLab_SuggestedPoCost"}>
              <div className="dx-field-label" style={{ width: "fit-content", padding: "0px" }}>
                <CheckBox id='chk_SuggestedPoCost' className="mini-checkbox" text="Suggested Po Cost"
                          value={activeSumColumn['CostSuggested']}
                          onValueChanged={(e) => onChangeActiveSumColumn( e,'CostSuggested' )}
                          key={"CostSuggested_cbx"}
                          />
              </div>
            </div>
            <div className="dx-field" style={{margin: "0px"}} key={"chkLab_CostApprover"}>
              <div className="dx-field-label" style={{ width: "fit-content", padding: "0px" }}>
                <CheckBox id='chk_CostApprover' className="mini-checkbox" text="Approver PO Cost"
                          value={activeSumColumn['CostApprover']}
                          onValueChanged={(e) => onChangeActiveSumColumn( e,'CostApprover' )}
                          key={"CostApprover_cbx"}
                          />
              </div>
            </div>
            <div className="dx-field" style={{margin: "0px"}} key={"chkLab_CostReviewer"}>
              <div className="dx-field-label" style={{ width: "fit-content", padding: "0px" }}>
                <CheckBox id='chk_CostReviewer' className="mini-checkbox" text="Reviewer PO Cost"
                          value={activeSumColumn['CostReviewer']}
                          onValueChanged={(e) => onChangeActiveSumColumn( e,'CostReviewer' )}
                          key={"CostReviewer_cbx"}
                          />
              </div>
            </div>
            <div className="dx-field" style={{margin: "0px"}} key={"chkLab_CostMarket"}>
              <div className="dx-field-label" style={{ width: "fit-content", padding: "0px" }}>
                <CheckBox id='chk_CostMarket' className="mini-checkbox" text="Market PO Cost"
                          value={activeSumColumn['CostMarket']}
                          onValueChanged={(e) => onChangeActiveSumColumn( e,'CostMarket' )}
                          key={"rowLinesOption_cbx"}
                          />
              </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", justifyContent:"space-between"}}>
              <Button
                  width={80}
                  text="Save"
                  type="normal"
                  stylingMode="contained"
                  onClick={onSaveSelection}
              />
              <Button
                  width={80}
                  text="Cancel"
                  type="normal"
                  stylingMode="contained"
                  onClick={onCancelSelection}
              />
            </div>
          </div>
        </Popup>
    </>
  );
};