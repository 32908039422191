import LinkCell from "../../grid/cellstyles/linkCell";
import { presetPopup } from "../../grid/dialog/presetPopup/presetPopup";
import { VendorModal } from "../../grid/dialog/VendorInventory/VendorModal";

export const getVendorInventoryColumn = () => {
const {render: VendorInventoryModalRender, openPopup: openPopupVendorInventory} = presetPopup({ Popup: VendorModal });

  const openVendorInventoryModal = (e) => {
    openPopupVendorInventory({internalId: e.row.data.InternalId, name: e.row.data.Name});
  }
          
  const columns = {};
  const localColumnKey = "col_ljlInventory";
  columns[localColumnKey] = {
    caption: "Vendor Inventory",
    localColumnKey: localColumnKey,
    dataField: "LJL Inventory",
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => LinkCell(e, openVendorInventoryModal),
    allowEditing: false,
    dataType: "number",
    width: 100
  };

  return {render: VendorInventoryModalRender, colProps: columns};
}