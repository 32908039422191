import LinkCell from "../../grid/cellstyles/linkCell";
import { presetPopup } from "../../grid/dialog/presetPopup/presetPopup";
import { InventoryModal } from "../../grid/dialog/Inventory/InventoryModal";

export const getInventoryColumns = () => {
  const {render: InventoryModalRender, openPopup: openPopupInventory} = presetPopup({ Popup: InventoryModal });

  const openInventoryModal = (e) => {
    openPopupInventory({internalId: e.row.data.InternalId, name: e.row.data.Name});
  }
  
  const columns = {};
  let localColumnKey = "col_qohTotal";
  columns[localColumnKey] = {
    caption: "QoH Total",
    localColumnKey: localColumnKey,
    dataField: "QoH Total",
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => LinkCell(e, openInventoryModal),
    allowEditing: false,
    dataType: "number",
    width: 100
  };

  localColumnKey = "col_qohCa";
  columns[localColumnKey] = {
    caption: "QoH CA",
    localColumnKey: localColumnKey,
    dataField: "QoHCA",
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => LinkCell(e, openInventoryModal),
    allowEditing: false,
    dataType: "number",
    width: 100
  };

  localColumnKey = "col_qohNC";
  columns[localColumnKey] = {
    caption: "QoH NC",
    localColumnKey: localColumnKey,
    dataField: "QoHNC",
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => LinkCell(e, openInventoryModal),
    allowEditing: false,
    dataType: "number",
    width: 100
  };

  localColumnKey = "col_availTotal";
  columns[localColumnKey] = {
    caption: "Avail Total",
    localColumnKey: localColumnKey,
    dataField: "Avail Total",
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => LinkCell(e, openInventoryModal),
    allowEditing: false,
    dataType: "number",
    width: 100
  };

  localColumnKey = "col_availCA";
  columns[localColumnKey] = {
    caption: "Avail CA",
    localColumnKey: localColumnKey,
    dataField: "AvailCA",
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => LinkCell(e, openInventoryModal),
    allowEditing: false,
    dataType: "number",
    width: 100
  };

  localColumnKey = "col_availNC";
  columns[localColumnKey] = {
    caption: "Avail NC",
    localColumnKey: localColumnKey,
    dataField: "AvailNC",
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => LinkCell(e, openInventoryModal),
    allowEditing: false,
    dataType: "number",
    width: 100
  };

  localColumnKey = "col_mod";
  columns[localColumnKey] = {
    caption: "Mod",
    localColumnKey: localColumnKey,
    dataField: "Mod",
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => LinkCell(e, openInventoryModal),
    allowEditing: false,
    dataType: "number",
    width: 100
  };

  localColumnKey = "col_availPoModBo";
  columns[localColumnKey] = {
    caption: "Avail + PO + Mod - BO",
    localColumnKey: localColumnKey,
    dataField: "Avail + PO + Mod - BO",
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => LinkCell(e, openInventoryModal),
    allowEditing: false,
    dataType: "number",
    width: 100
  };

  localColumnKey = "col_refinish";
  columns[localColumnKey] = {
    caption: "Refinish",
    localColumnKey: localColumnKey,
    dataField: "Refinish",
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => LinkCell(e, openInventoryModal),
    allowEditing: false,
    dataType: "number",
    width: 100
  };

  localColumnKey = "col_scrDent";
  columns[localColumnKey] = {
    caption: "ScrDent",
    localColumnKey: localColumnKey,
    dataField: "ScrDent",
    cssClass: "tooltip-target-" + localColumnKey,
    cellRender: (e) => LinkCell(e, openInventoryModal),
    allowEditing: false,
    dataType: "number",
    width: 100
  };

  return {render: InventoryModalRender, colProps: columns};
}