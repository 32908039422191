import apiClient from "../../apis/items";

export const addSavedSearchByColumn = async (searchName, search) => {
    const data = { "searchName": searchName, "query": JSON.stringify(search) };
    await apiClient.post(`/api/SavedSearch/column/insert`, data)
}

export const updateSavedSearchByColumn = async (searchName, search) => {
    const data = { "searchName": searchName, "query": JSON.stringify(search)  };
    await apiClient.put(`/api/SavedSearch/column/update`, data)
}

export const getSavedSearchByColumn = async () => {
    var response = await apiClient.get(`/api/SavedSearch/column/all`)
    return response.data;
}

export const getSavedSearchByColumnByName = async (searchName) => {
    var response = await apiClient.get(`/api/SavedSearch/column/${searchName}`)
    return response.data;
}



export const addSavedSearch = async (searchName, search, type) => {
    const data = { "searchName": searchName, "query": JSON.stringify(search) };
    await apiClient.post(`/api/SavedSearch/${type}/insert`, data)
}

export const updateSavedSearch = async (searchName, search, type) => {
    const data = { "searchName": searchName, "query": JSON.stringify(search)  };
    await apiClient.put(`/api/SavedSearch/${type}/update`, data)
}

export const getSavedSearchByName = async (searchName, type) => {
    var response = await apiClient.get(`/api/SavedSearch/${type}/${searchName}`)
    return response.data;
}
    