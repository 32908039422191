import { useEffect } from "react";
import { MainBox } from "./components/mainPage/mainBox"
import { useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { RequestProfileData } from "./common/authentication";
import { appInsights } from "./common/ApplicationInsights";
import { MenuWrapper } from "./components/Menu/MenuWrapper";
import { LoadPanel } from "devextreme-react";
import Error from "./components/error/error";
import { GlobalNotification } from "./GlobalNotification";
import useSignalR from "./components/mainGrid/Hooks/useSignalR";
import { GetSilentTokent } from "./components/authentication/AuthenticationHelpers";
import { v4 as uuidv4 } from 'uuid';
const timeoutInMins = 15;

export function Website() {
    const dispatch = useDispatch();
    const { instance, accounts } = useMsal();
    const userDataLoading = useSelector(state => state.userInfo.loading);
    const error = useSelector(state => state.userInfo.error);
    const authenticatedUser = useSelector(state => state.userInfo.userAuthenticated);
    const hubUrl = process.env.REACT_APP_BASE_URL;
    useSignalR(hubUrl);

    if (!window.sessionStorage.getItem('session_id')) {
        const generated_id = uuidv4();
        window.sessionStorage.setItem('session_id', generated_id);
    }

    useSignalR(hubUrl, authenticatedUser);

    useEffect(() => {
        appInsights.trackPageView("MainGrid");
        RequestProfileData(instance, accounts, authenticatedUser, dispatch);
    }, [instance, accounts, authenticatedUser]);


    setTimeout(async () => {
        try {
            await GetSilentTokent();
        } catch (error) {
            console.error('Error getting silent token on idle:', error);
        }
    }, timeoutInMins * 60000);

    if (error === true) {
        return <Error />
    }

    if (userDataLoading) {
        return (
            <LoadPanel
                visible={userDataLoading}
                message="Loading User Information"
            />
        );
    }

    return (
        <MenuWrapper origin={"main"}>
            <GlobalNotification/>
            <MainBox componentIndex={1} />
        </MenuWrapper>
    );
}