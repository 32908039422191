import { Link } from '@mui/material';

const LinkCellForDiffValue = (cellData, conditionalValue, action, params) => {

  if (conditionalValue === cellData.value)
    return (cellData.value);

  return (
    <div>
      <Link 
        component="button"
        variant="body2"
        onClick={() => {
          return action(cellData, params)
        }}
        style={{color:"#626262", fontFamily:"verdana", fontSize:"9px"}}
      >
        {cellData.value}
      </Link>
    </div>

  );
}
export default LinkCellForDiffValue;